<template>
  <div>
    <h1>Create SKU Form</h1>
    <a @click="$router.back()">Back</a>
    <div
      v-if="productData"
      class="mb-5 mt-2"
    >
      product: {{ productData.title.en }}
    </div>
    <sku-form
      v-if="productData"
      :product="productData"
      mode="create"
      :loading="loading"
      :disabled-fields="[ ['voucher', 'plan'].includes(productData.nature) ? 'stock' : '' ]"
      :errors="skuErrors"
      @submit="handleSkuForm"
    />
  </div>
</template>

<script>
import { useNotifySuccess } from '@/composables'
import router from '@/router'
import { } from '@mdi/js'
import useProduct from '../useProduct'
import SkuForm from './SkuForm.vue'
import useSkuForm from './useSkuForm'

export default {
  components: { SkuForm },

  setup() {
    const { loadProduct, productData } = useProduct()
    const {
      storeSkuForm, makeSkuBoilerplate, loading, skuErrors,
    } = useSkuForm()

    const productId = router.currentRoute.query.product_id

    const handleSkuForm = async form => {
      const res = await storeSkuForm(productId, form)
      console.log('stored sku res', res)
      await makeSkuBoilerplate(res.data.data.id)
      router.push({
        name: 'product-view',
        params: { id: productId },
        query: { tab: 'SKUS' },
      })
      useNotifySuccess({ content: 'SKU Create Succeeded.' })
    }

    loadProduct(productId)

    return {
      skuErrors,
      productData,
      loading,
      handleSkuForm,
    }
  },
}
</script>
